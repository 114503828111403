import React, { useState, useEffect, useCallback } from "react";
import { Select, Input, message, Button, Table, Space, Spin } from "antd";
import { http } from "../../../../services/http";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getToday, getFiveDaysAgo } from "../../../../utils/getDays";

const ShipProductEdit = ({ datas, onClose, visible }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.main.user);
  const { Option } = Select;

  const today = getToday();
  const fiveDaysAgo = getFiveDaysAgo();

  const [tableData, setTableData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [data, setData] = useState({
    fdata: [],
    value: "",
    warehouseCode: "",
    salesUnit: "",
  });

  const { fdata } = data;
  const [selectedWhsCodes, setSelectedWhsCodes] = useState({});
  const [date, setDate] = useState(today);

  useEffect(() => {
    fetchData();

    if (visible) {
      resetForm();
    }
  }, [visible]);

  useEffect(() => {
    if (datas) {
      setTableData(
        datas.documentLines.map((item, index) => ({
          key: index,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode ? item.itemCode : "",
          currency: item.currency ? item.currency : "USD",
          quantity: item.quantity ? item.quantity : "",
          measureUnit: item.measureUnit ? item.measureUnit : "",
          price: item.price ? item.price : "",
          inventoryQuantity: item.inventoryQuantity
            ? item.inventoryQuantity
            : "",
          lineTotal: item.lineTotal ? item.lineTotal : "",
          whsCode: item.warehouseCode ? item.warehouseCode : "",
          whsName: item.whsName ? item.whsName : "",
          lineStatus: item.lineStatus ? item.lineStatus : "",
          lineNum: item.lineNum ? item.lineNum : "",
        })),
      );
    }
  }, [datas]);

  const fetchData = async () => {
    try {
      setLoadingData(true);
      const { data } = await http.get(`api/items`);
      const mappedItems = data.map((item) => ({
        code: item.itemCode,
        name: item.itemName,
        price: item.itemPrice.price,
        currency:
          item.itemPrice.currency !== null ? item.itemPrice.currency : 0,
        priceList: item.itemPrice.priceList,
        measureUnit: item.salesUnit,
      }));
      setData((prevState) => ({
        ...prevState,
        fdata: mappedItems,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSelectChange = (value, key) => {
    const selectedItem = fdata.find((item) => item.name === value);

    if (selectedItem) {
      const itemCode = selectedItem.code;
      const unitPrice = selectedItem.price;
      const currency = selectedItem.currency;
      const measureUnit = selectedItem.measureUnit;

      setTableData((prevData) =>
        prevData.map((item) =>
          item.key === key
            ? {
                ...item,
                itemDescription: value,
                itemCode: itemCode,
                currency: currency,
                price: unitPrice,
                measureUnit: measureUnit,
              }
            : item,
        ),
      );
    } else {
      console.log("Selected item is undefined.");
    }
  };

  const patchData = useCallback(async () => {
    setBtnLoading(true);
    const documentLines = tableData.map((item, index) => ({
      lineNum: item.lineNum,
      itemCode: item.itemCode,
      quantity: Number(item.quantity),
      unitPrice: Number(item.price),
      currency: item.currency,
      whsCode: item.whsCode,
      measureUnit: item.measureUnit,
    }));
    try {
      const res = await http.patch(`api/salesorder`, {
        docEntry: datas.docEntry,
        salesPersonCode: datas.salesPersonCode,
        // docCurrency: datas.currency[0],
        docDueDate: date,
        documentLines,
      });
      message.success(t("sucCreated"));
      setTimeout(() => {
        onClose();
        window.location.reload();
        resetForm();
      }, 800);
    } catch (err) {
      message.error(t("errCreation") + err.message);
    } finally {
      setBtnLoading(false);
    }
  }, [tableData, datas, onClose, t, selectedWhsCodes]);

  const handleQuantityChange = useCallback((e, key) => {
    const newQuantity = e.target.value;
    setTableData((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, quantity: newQuantity } : item,
      ),
    );
  }, []);

  const removeItem = (key) => {
    if (tableData.length > 1) {
      const newData = tableData.filter((item) => item.key !== key);
      setTableData(newData);
    } else {
      message.error(t("atLeastOneItem"));
    }
  };

  const addItem = () => {
    const newKey = tableData.length
      ? tableData[tableData.length - 1].key + 1
      : 0;
    const newItem = {
      key: newKey,
      itemDescription: "",
      itemCode: "",
      currency: "USD",
      quantity: "",
      measureUnit: "",
      price: "",
      inventoryQuantity: "",
      lineTotal: "",
      whsCode: datas.warehouseCode[0],
      whsName: datas.whsName[0],
      lineStatus: "",
      lineNum: tableData.length + 1,
    };
    setTableData([...tableData, newItem]);
  };

  const resetForm = () => {
    setData((prevState) => ({
      ...prevState,
      value: "",
      warehousingCode: "",
      salesUnit: "",
    }));
    setSelectedWhsCodes({});
    setTableData([]);
  };

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (text, record) => (
        <Spin spinning={loadingData}>
          <Select
            value={text}
            showSearch
            className="w-[350px]"
            optionFilterProp="children"
            onChange={(value) => handleSelectChange(value, record.key)}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={record.lineStatus === "C"}
          >
            {fdata.map((item) => (
              <Option key={item.code} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Spin>
      ),
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleQuantityChange(e, record.key)}
          disabled={record.lineStatus === "C"}
        />
      ),
    },
    {
      title: t("measureUnit"),
      dataIndex: "measureUnit",
      key: "measureUnit",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : ""}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : ""}`}
          >
            {formattedText} {record.currency}
          </span>
        );
      },
    },

    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : ""}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => removeItem(record.key)}>-</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="w-full">
      <div id="ship-product-form" className="w-full">
        <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("mijoz")}</p>
            <Input
              type="text"
              value={datas.cardName}
              className="flex h-12 w-full items-center justify-center sm:w-[250px]"
              style={{ color: "black" }}
              disabled
            />
          </div>

          <div className="flex flex-col gap-3">
            <p className="font-nunito">{t("date")}</p>
            <Input
              type="date"
              value={date}
              className="w-[150px]"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              max={today}
              min={fiveDaysAgo}
            />
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className="border-1 mt-12 border-black"
          scroll={{ x: "100%" }}
        />

        <div className="mt-4 flex justify-start">
          <Button className="bg-[#0A4D68] text-white" onClick={addItem}>
            +
          </Button>
        </div>

      
        <div className="flex h-[100%] w-[100%] flex-col justify-center">
          <div className="mt-20 flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
            <Button
              onClick={patchData}
              className="h-12 w-[300px] rounded-3xl bg-[#0A4D68] text-white"
              disabled={btnLoading}
              loading={btnLoading}
            >
              {t("save")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipProductEdit;
