import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, Select } from "antd";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import moment from "moment";
import PurchaseDeliveryModal from "../../../components/WhrManager/purchase-deliveryModal";

const PurchaseDelivery = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [typeOfTransport, setTypeOfTransport] = useState("");
  const [numberOfTransport, setNumberOfTransport] = useState("");
  const [partyNumber, setPartyNumber] = useState("");
  const [status, setStatus] = useState("");

  const [total, setTotal] = useState("");

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "So'rov yuborilsin", value: "SurovYuoborilsin" },
    { label: "So'rov yuborildi", value: "SurovYuborildi" },
    { label: "Yuk ko'chirildi", value: "YukKochirildi" },
  ];

  const [selectedCardName, setSelectedCardName] = useState("");
  const [selectedDocNum, setSelectedDocNum] = useState("");

  const fetchData = useCallback(
    async (
      CardName,
      DocNum,
      startDate,
      endDate,
      TypeOfTransport,
      NumberOfTransport,
      PartyNumber,
      RequesForShipping,
      page,
    ) => {
      try {
        setLoading(true);
        setDataSource([]);

        let url = `api/sales/purchase-delivery-notes?${CardName ? `CardName=${CardName}&` : ""}${startDate ? `StartDate=${startDate}&` : ""}${endDate ? `EndDate=${endDate}&` : ""}${DocNum ? `DocNum=${DocNum}&` : ""}${TypeOfTransport ? `TypeOfTransport=${TypeOfTransport}&` : ""}${NumberOfTransport ? `NumberOfTransport=${NumberOfTransport}&` : ""}${PartyNumber ? `PartyNumber=${PartyNumber}&` : ""}${RequesForShipping ? `RequesForShipping=${RequesForShipping}&` : ""}Skip=${page}`;

        const { data } = await http.get(url);

        setDataSource(data.data);
        const hasMore = data.data.length === 20 ? true : false;
        setHasMoreData(hasMore);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [selectedCardName, selectedDocNum],
  );

  useEffect(() => {
    fetchData(
      selectedCardName,
      selectedDocNum,
      startDate,
      endDate,
      typeOfTransport,
      numberOfTransport,
      partyNumber,
      status,
      currentPage,
    );
  }, [
    fetchData,
    selectedCardName,
    selectedDocNum,
    startDate,
    endDate,
    typeOfTransport,
    numberOfTransport,
    partyNumber,
    status,
    currentPage,
  ]);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  let searchInput = null;

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "docNum") {
          if (value) {
            setSelectedDocNum(value);
          } else {
            setSelectedDocNum("");
          }
        }
        if (dataIndex === "cardName") {
          if (value) {
            setSelectedCardName(value);
          } else {
            setSelectedCardName("");
          }
        }
        if (dataIndex === "typeOfTransport") {
          if (value) {
            setTypeOfTransport(value);
          } else {
            setTypeOfTransport("");
          }
        }
        if (dataIndex === "numberOfTransport") {
          if (value) {
            setNumberOfTransport(value);
          } else {
            setNumberOfTransport("");
          }
        }
        if (dataIndex === "partyNumber") {
          if (value) {
            setPartyNumber(value);
          } else {
            setPartyNumber("");
          }
        }

        confirm();
      }, 1000),
    [],
  );

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;

    debouncedSearch(value, dataIndex, confirm);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(e, confirm, dataIndex);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: t("namePur"),
      dataIndex: "cardName",
      key: "cardName",
      width: 150,
      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      width: 120,
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("numPur"),
      dataIndex: "docNum",
      key: "docNum",
      width: 120,
      ...getColumnSearchProps("docNum"),
    },

    {
      title: t("typeOfTransport"),
      dataIndex: "typeOfTransport",
      key: "typeOfTransport",
      width: 150,
      render: (text, record) => {
        return text || "-";
      },
      ...getColumnSearchProps("typeOfTransport"),
    },
    {
      title: t("numberOfTransport"),
      dataIndex: "numberOfTransport",
      key: "numberOfTransport",
      width: 150,
      render: (text, record) => {
        return text || "-";
      },
      ...getColumnSearchProps("numberOfTransport"),
    },
    {
      title: t("partyNumber"),
      dataIndex: "partyNumber",
      key: "partyNumber",
      width: 150,
      render: (text, record) => {
        return text || "-";
      },
      ...getColumnSearchProps("partyNumber"),
    },
    {
      title: t("status"),
      dataIndex: "requesForShipping",
      key: "requesForShipping",
      width: 180,

      render: (text, record) => {
        const status = record.requesForShipping
          ? orderStatus.find((status) => {
              return status.value === record.requesForShipping;
            })
          : "-";
        console.log("orderStatus=", orderStatus);
        console.log("record.requesForShipping=", record.requesForShipping);

        console.log("status=", status);
        return <span>{status.label}</span>;
      },
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Description"
          value={status}
          onChange={(value) => {
            setCurrentPage(0);
            setStatus(value);
          }}
          options={orderStatus.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          })}
        />
      ),
    },
  ];

  return (
    <div className="flex w-full">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Header
          title={"purchase-delivery"}
          currentPage={currentPage + 1}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          hasMoreData={hasMoreData}
          columns={columns}
          fdata={dataSource}
          loading={loading}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
          startDate={startDate}
          endDate={endDate}
          formattedDate={moment().format("YYYY-MM-DD")}
          handleRowClick={handleRowClick}
          total={total}
          scrollX={1400}
        />

        <PurchaseDeliveryModal
          visible={isModalVisible}
          data={selectedRowData}
          onClose={() => setIsModalVisible(false)}
          refetch={() => {
            setCurrentPage(0);
            fetchData(
              selectedCardName,
              selectedDocNum,
              startDate,
              endDate,
              typeOfTransport,
              numberOfTransport,
              partyNumber,
              status,
              currentPage,
            );
          }}
        />
      </div>
    </div>
  );
};

export default PurchaseDelivery;
