import React, { useState, useEffect } from "react";
import { Select, Input, message, Spin, Button, Table, Modal, Tag } from "antd";
import { http } from "../../../../services/http";
import { useTranslation } from "react-i18next";
import ShipProductModalEdit from "../../../../components/WhrManager/shipProductEdit";
import { getFiveDaysAgo, getToday } from "../../../../utils/getDays";
import moment from "moment";

const ShipProduct = ({ datas, onClose, visible }) => {
  const { t } = useTranslation();
  const today = getToday();
  const fiveDaysAgo = getFiveDaysAgo();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [driverId, setDriverId] = useState("");
  const [manager, setManager] = useState("");
  const [date, setDate] = useState(today);
  const [status, setStatus] = useState("");

  const [loadingData, setLoadingData] = useState({
    ddata: false,
    wdata: false,
    mdata: false,
  });

  const [data, setData] = useState({
    ddata: [],
    value: "",
    wdata: [],
    mdata: [],
  });

  const { ddata, wdata, mdata } = data;
  const defaultWarehouse = "Angr-sk";

  const docTotalInventory = datas?.documentLines.reduce((total, item) => {
    return total + item.inventoryQuantity;
  }, 0);

  const sum =
    datas?.docCur === "UZS"
      ? new Intl.NumberFormat("fr-FR").format(datas?.docTotalFc)
      : new Intl.NumberFormat("fr-FR").format(datas?.docTotal);

  const initialSelectedWarehouses = datas?.documentLines.reduce(
    (acc, _, index) => {
      acc[index] = defaultWarehouse;
      return acc;
    },
    {},
  );
  const [selectedWarehouses, setSelectedWarehouses] = useState(
    initialSelectedWarehouses,
  );
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "Tasdiqlandi", value: "Tasdiqlandi" },
    { label: "Mas'ulga biriktirildi", value: "Biriktirildi" },
    { label: "Buyurtma yuklandi", value: "BuyurtmaYuklandi" },
    { label: "Yuklash tasdiqlandi", value: "YuklashTasdiqlandi" },
  ];

  const handleSelectClick = () => {
    setSelectedComments(datas.comments);
    setIsCommentModalVisible(true);
  };

  const handleModalOk = () => {
    setIsCommentModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsCommentModalVisible(false);
  };

  const handleWarehouseChange = (value, rowIndex) => {
    const selectedWarehouse = wdata.find(
      (warehouse) => warehouse.code === value,
    );
    const warehouseName = selectedWarehouse ? selectedWarehouse.code : "";
    setSelectedWarehouses((prevSelectedWarehouses) => ({
      ...prevSelectedWarehouses,
      [rowIndex]: warehouseName,
    }));
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoadingData({ ddata: true, wdata: true, mdata: true });
      await Promise.all([fetchDrivers(), fetchWarehouse(), fetchWhsManagers()]);
      setLoadingData({ ddata: false, wdata: false, mdata: false });
    };
    fetchAllData();

    if (visible) {
      resetForm();
    }
  }, [visible]);

  useEffect(() => {
    if (datas) {
      setManager(
        datas?.responsiblePerson ? Number(datas.responsiblePerson) : "",
      );
      setDriverId(datas.driver ? datas.driver : "");
      const status = datas.orderStatus
        ? orderStatus.find((status) => {
            return status.value === datas.orderStatus;
          })
        : "-";
      setStatus(status.label);
    }
  }, [datas]);

  const fetchDrivers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/driver-business-partners?skip=0`,
      );

      setData((prevState) => ({
        ...prevState,
        ddata: data.data,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWhsManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );

      setData((prevState) => ({
        ...prevState,
        mdata: data.data,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const { data } = await http.get(`api/warehouses`);
      const mappedItems = data
        .filter((item) => item.warehouseCode !== "01")
        .map((item) => ({
          code: item.warehouseCode,
          name: item.warehouseName,
        }));
      setData((prevState) => ({
        ...prevState,
        wdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const editManager = async () => {
    if (manager) {
      setLoading(true);

      try {
        await http.patch(
          `api/sales/responsible-person/${datas.docEntry}?responsiblePerson=${manager}`,
        );
        message.success(t("sucEditedManager"));
      } catch (error) {
        console.log("Error");
        message.error(t("errCreation") + error.message);
        console.log("Error:", error.message);
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("selectResponsible"));
    }
  };

  const confirmDelivery = async () => {
    setLoading3(true);
    const data = datas.documentLines.map((item, index) => ({
      lineNumber: item.lineNum,
      warehouseCode: selectedWarehouses[index],
    }));
    console.log(...data);

    try {
      await http.patch(`api/sales/confirmed/${datas.docEntry}`, {
        ...data,
      });
      message.success(t("sucCreated"));

      setTimeout(() => {
        resetForm();
        onClose();
      }, 100);
    } catch (error) {
      console.log("Error");
      message.error(t("errCreation") + error.message);
      console.log("Error:", error.message);
    } finally {
      setLoading3(false);
    }
  };

  const editDriver = async () => {
    if (driverId) {
      setLoading2(true);

      try {
        await http.patch(
          `api/sales/driver/${datas.docEntry}?driver=${driverId}`,
        );
        message.success(t("sucEditedDriver"));
      } catch (error) {
        console.log("Error");
        message.error(t("errCreation") + error.message);
        console.log("Error:", error.message);
      } finally {
        setLoading2(false);
      }
    } else {
      message.error(t("selectDriver"));
    }
  };

  const editStatus = async () => {
    setLoading4(true);

    try {
      await http.patch(`api/sales/assigned/${datas.docEntry}`);
      message.success(t("sucCreated"));

      setTimeout(() => {
        resetForm();
        onClose();
      }, 800);
    } catch (error) {
      console.log("Error");
      message.error(t("errCreation") + error.message);
      console.log("Error:", error.message);
    } finally {
      setLoading4(false);
    }
  };

  const onEdit = async () => {
    setIsModalVisible(true);
  };

  const resetForm = () => {
    setData((prevState) => ({
      ...prevState,
      value: "",
      managerId: "",
    }));
  };

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
      width: 280,
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",

      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {formattedText}
          </span>
        );
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
      text: "center",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"} text-center`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {formattedText} {record.currency}
          </span>
        );
      },
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "inventoryQuantity",
      key: "inventoryQuantity",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {`${text} `}
          </span>
        );
      },
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {`${text} `}
          </span>
        );
      },
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record, index) => {
        const formatSum =
          record.currency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record?.totalFrgn)
            : new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {`${formatSum} ${record.currency}`}
          </span>
        );
      },
    },
    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
      render: (text, record, index) => (
        <Select
          value={selectedWarehouses[index]}
          onChange={(value) => handleWarehouseChange(value, index)}
          className="flex h-10 w-[200px] items-center justify-center text-center text-black"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          disabled={record.lineStatus === "C"}
        >
          {wdata
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((warehouse) => (
              <Select.Option key={warehouse.code} value={warehouse.code}>
                {warehouse.name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
  ];

  const tableData = datas.documentLines.map((item, index) => ({
    key: index,
    itemDescription: item.itemDescription,
    quantity: item.quantity,
    measureUnit: item.measureUnit,
    price: item.price,
    inventoryQuantity: item.inventoryQuantity,
    lineTotal: item.lineTotal,
    whsName: selectedWarehouses[index],
    ugpName: item.ugpName,
    currency: item.currency,
    totalFrgn: item.totalFrgn || 0,
  }));

  return (
    <div className="w-full">
      <div id="ship-product-form" className="w-full">
        <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("mijoz")}</p>
            <Input
              type="text"
              value={datas.cardName}
              className="flex h-10 w-full items-center justify-center font-bold text-black sm:w-[250px]"
              style={{ color: "black" }}
              disabled
            />
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("date")}</p>

            <Input
              type="date"
              value={date}
              className="flex w-full items-center justify-center font-bold text-black sm:w-[120px]"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              max={today}
              min={fiveDaysAgo}
            />
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className="border-1 mt-12 border-black"
        />

        <div className="mt-10 flex items-center justify-between">
          <div className="flex flex-col gap-3">
            <p>{t("comments")}</p>
            <Select
              value={datas.comments}
              className="flex h-10 w-full items-center justify-center font-bold text-black sm:w-[250px]"
              style={{ color: "black" }}
              disabled
              onClick={handleSelectClick}
            />
            <Modal
              title={t("comments")}
              visible={isCommentModalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
            >
              <p>{selectedComments}</p>
            </Modal>
          </div>
          <div className="flex items-center justify-end gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("docTotalQuantity")}</p>
              <Input
                type="text"
                value={`${docTotalInventory} кг`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] font-bold text-black active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("totalPrice")}</p>
              <Input
                type="text"
                value={`${sum} ${datas.docCur}`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] font-bold text-black active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
          <div className="flex flex-col gap-3">
            <p>{t("responsiblePerson")}</p>
            <Spin spinning={loadingData.mdata}>
              <Select
                value={manager}
                placeholder={t("chooseManager")}
                onChange={(val) => {
                  setManager(val);
                }}
                showSearch
                className="flex h-10 w-full items-center justify-center sm:w-[300px]"
              >
                {mdata
                  .filter((item) => item.firstName !== null)
                  .map((item) => (
                    <Select.Option key={item.value} value={item.employeeId}>
                      {`${item.firstName} ${item.lastName}`}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p>{t("driver")}</p>
            <Spin spinning={loadingData.ddata}>
              <Select
                value={driverId}
                placeholder={t("chooseDriver")}
                onChange={(val) => {
                  setDriverId(val);
                }}
                showSearch
                className="flex h-10 w-full items-center justify-center sm:w-[300px]"
              >
                {ddata
                  .filter((item) => item.cardName !== null)
                  .map((item) => (
                    <Select.Option key={item.value} value={item.cardCode}>
                      {item.cardName}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 sm:mt-10">
        <div className="flex items-center gap-2">
          <span className="font-bold">{t("statusSale")} : </span>
          {datas?.orderToDeliveryTime && (
            <Tag className="text-sm" color={"blue"}>
              {status}
            </Tag>
          )}
        </div>
        <div className="flex items-center gap-2">
          <span className="font-bold">{t("timeRp")} : </span>

          {datas?.orderToDeliveryTime && (
            <Tag color={"blue"}>
              {moment(datas?.orderToDeliveryTime).format("DD-MM-YYYY HH:mm")}
            </Tag>
          )}
        </div>
        <div className="flex items-center gap-2">
          <span className="font-bold">{t("deliveredTime2")} : </span>
          {datas?.orderDeliveredTime && (
            <Tag className="text-sm" color={"blue"}>
              {moment(datas?.orderDeliveredTime).format("DD-MM-YYYY HH:mm")}
            </Tag>
          )}
        </div>
        {/* <div className="flex items-center gap-2">
          <span className="font-bold">{t("deliveredTime")} : </span>
          {datas?.deliveredTime && (
            <Tag className="text-sm" color={"blue"}>
              {moment(datas?.deliveredTime).format("DD-MM-YYYY HH:mm")}
            </Tag>
          )}
        </div> */}
        {/* <div className="flex items-center gap-2">
          <span className="font-bold">{t("confirmDeliveryTime")} : </span>

          {datas?.orderDeliveredTime && (
            <Tag color={"blue"}>
              {moment(datas?.orderToDeliveryTime).format("DD-MM-YYYY HH:mm")}
            </Tag>
          )}
        </div> */}
      </div>

      <div className="mt-10 flex w-full flex-col items-start justify-between gap-4 sm:flex-row">
        <div className="flex w-full flex-col gap-3">
          <Button
            onClick={editManager}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
            disabled={loading}
            loading={loading}
          >
            {t("assignToResponsible")}
          </Button>
        </div>

        <div className="flex w-full flex-col gap-3">
          <Button
            onClick={onEdit}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
          >
            {t("edit")}
          </Button>
        </div>
        <div className="flex w-full flex-col gap-3">
          <Button
            onClick={confirmDelivery}
            disabled={loading3 || datas.orderStatus !== "BuyurtmaYuklandi"}
            loading={loading3}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
          >
            {t("confirmDelivery")}
          </Button>
        </div>
        <div className="flex w-full flex-col gap-3">
          <Button
            onClick={editStatus}
            disabled={loading4 || !manager || !driverId}
            loading={loading4}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
          >
            {t("attach")}
          </Button>
        </div>
        <div className="flex w-full flex-col gap-3">
          <Button
            onClick={editDriver}
            disabled={loading2}
            loading={loading2}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
          >
            {t("assignDriver")}
          </Button>
        </div>
      </div>
      <ShipProductModalEdit
        visible={isModalVisible}
        data={datas}
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default ShipProduct;
