import React, { useState, useEffect } from "react";
import { Modal, Table, Select, Button, message, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { http } from "../../services/http";
import moment from "moment";

const RowDataOmborModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation();

  const [responsiblePersonData, setResponsiblePersonData] = useState([]);
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    fetchResponsibleManagers();
  }, []);

  useEffect(() => {
    if (data) {
      setResponsiblePerson(
        data?.responsiblePerson ? Number(data?.responsiblePerson) : "",
      );
      console.log("responsiblePerson", data?.responsiblePerson);
    }
  }, [data]);

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("measureUnit"),
      dataIndex: "uoMEntry",
      key: "uoMEntry",
    },
  ];

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );
      setResponsiblePersonData(data.data);
      console.log("warehouse-employee", data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const postData = async () => {
    setLoading(true);
    try {
      await http.patch(
        `api/sales/inventory-transfer-request-responsible-person/${data?.docEntry}?responsiblePerson=${responsiblePerson}`,
      );
      message.success(t("sucEditedManager"));

      setTimeout(() => {
        // resetForm();
        onClose();
      }, 800);
    } catch (error) {
      message.error(t("errCreation") + error.message);
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const postRequest = async () => {
    setLoading2(true);
    try {
      await http.patch(
        `api/sales/inventory-transfer-requests-confirmed/${data?.docEntry}`,
      );
      message.success(t("sucCreated"));

      setTimeout(() => {
        // resetForm();
        onClose();
      }, 800);
    } catch (error) {
      message.error(t("errCreation") + error.message);
      console.log("Error:", error);
    } finally {
      setLoading2(false);
    }
  };
  if (!data) return null;

  const tableData = data.stockTransferLines.map((item, index) => ({
    key: index,
    itemDescription: item.itemDescription,
    quantity: item.quantity,
    uoMEntry: item.uoMEntry,
  }));

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docEntry}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="70%"
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className="border-1 mt-4 w-full border-black"
          scroll={{ x: "100%" }}
        />
        <div className="mt-4 flex flex-col justify-between sm:mt-10 sm:flex-row">
          <div className="flex flex-col gap-3">
            <p>{t("responsiblePerson")}</p>
            <Select
              value={responsiblePerson}
              className="flex h-12 w-full items-center justify-center sm:w-[300px]"
              options={responsiblePersonData.map((item) => ({
                value: item.employeeId,
                label: `${item.firstName} ${item.lastName}`,
              }))}
              onChange={(value) => setResponsiblePerson(value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="mt-4 flex flex-col gap-3 sm:mt-10">
            <Button
              onClick={postData}
              className="h-12 w-full rounded-lg bg-[#0A4D68] text-white sm:w-auto"
              disabled={loading || !responsiblePerson}
              loading={loading}
            >
              {t("patchRepsonsiblePerson")}
            </Button>

            <div className="flex items-center gap-2">
              <span className="font-bold">{t("timeRp")} : </span>
              {data?.orderToDeliveryTime && (
                <Tag color={"blue"}>
                  {moment(data?.orderToDeliveryTime).format("DD-MM-YYYY HH:mm")}
                </Tag>
              )}
            </div>
          </div>
          <div className="mt-4 flex flex-col gap-3 sm:mt-10">
            <Button
              onClick={postRequest}
              className="h-12 w-full rounded-lg bg-[#0A4D68] text-white sm:w-auto"
              disabled={loading2}
              loading={loading2}
            >
              {t("acceptInventory")}
            </Button>
            <div className="flex items-center gap-2">
              <span className="font-bold">{t("acceptedInventoryTime")} : </span>
              {data?.deliveredTime && (
                <Tag color={"blue"}>
                  {moment(data?.deliveredTime).format("DD-MM-YYYY HH:mm")}
                </Tag>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RowDataOmborModal;
