import dayjs from "dayjs";

export const getToday = () => {
  const today = new Date(); // Create a new Date instance
  const day = ("0" + today.getDate()).slice(-2);
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const year = today.getFullYear();
  return `${year}-${month}-${day}`; // Return the formatted date
};

export const getFiveDaysAgo = () => {
  const fiveDaysAgo = new Date(); // Create a new Date instance
  fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5); // Subtract 5 days
  const dayFiveAgo = ("0" + fiveDaysAgo.getDate()).slice(-2);
  const monthFiveAgo = ("0" + (fiveDaysAgo.getMonth() + 1)).slice(-2);
  const yearFiveAgo = fiveDaysAgo.getFullYear();
  return `${yearFiveAgo}-${monthFiveAgo}-${dayFiveAgo}`; // Return the formatted date
};

const getFiveDaysAgo2 = () => dayjs().subtract(5, "days");
const getToday2 = () => dayjs();

export const disabledDate = (current) => {
  if (!current) return false;
  return (
    current.isBefore(getFiveDaysAgo2(), "day") ||
    current.isAfter(getToday2(), "day")
  );
};
