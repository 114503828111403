import React, { useEffect } from "react";
import PathListener from "./components/path-listeners";
import RoutesComponent from "./routes/routes";
import { InitOneSignal } from "./utils/initOneSignal";

export default function App() {
  useEffect(() => {
    try {
      InitOneSignal();
    } catch (e) {
      //
    }
  }, []);

  return (
    <>
      <PathListener />
      <RoutesComponent />
    </>
  );
}
