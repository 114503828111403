import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, DatePicker, Button, Select } from "antd";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import { columnShippedSale } from "../../../utils/columns";
import RowDataShipmentModal from "../../../components/WhrManager/row-data-shipment";
import moment from "moment";
import RemoveItemModal from "../../../components/WhrManager/removeModal";
const { RangePicker } = DatePicker;

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const Shipments = () => {
  const { t } = useTranslation();

  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRemove, setRemove] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const [docNum, setDocNum] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [docStatus, setDocStatus] = useState("");
  const [endDate, setEndDate] = useState(formattedDate);
  const [total, setTotal] = useState(0);

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "Tasdiqlandi", value: "Tasdiqlandi" },
    { label: "Mas'ulga biriktirildi", value: "Biriktirildi" },
    { label: "Buyurtma yuklandi", value: "BuyurtmaYuklandi" },
    { label: "Yuklash tasdiqlandi", value: "YuklashTasdiqlandi" },
  ];

  const fetchData = useCallback(
    async (
      page,
      ScardName,
      SdocNum,
      SslpName,
      SstartDate,
      SendDate,
      status,
    ) => {
      try {
        let url = "";
        setLoading(true);
        if (
          ScardName === "" &&
          SdocNum === "" &&
          SslpName === "" &&
          SstartDate === ""
        ) {
          url = `api/yuklanganbuyurtmalar?orderStatus=${status}&cardName=${ScardName}&docNum=${SdocNum}&startDate=${SstartDate}&endDate=${SendDate}&pageToken=${page}`;
        } else {
          url = `api/yuklanganbuyurtmalar?orderStatus=${status}&cardName=${ScardName}&docNum=${SdocNum}&startDate=${SstartDate}&endDate=${SendDate}`;
        }
        const { data } = await http.get(url);

        const formattedData = data.docs;
        console.log(formattedData);
        const hasMore = formattedData.length === 10 ? true : false;
        setFData(formattedData);
        setHasMoreData(hasMore);
        setTotal(
          data.data.reduce((total, item) => {
            return total + item.docTotal;
          }, 0),
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [cardName, docNum, startDate, endDate, slpName, docStatus],
  );

  useEffect(() => {
    fetchData(
      currentPage,
      cardName,
      docNum,
      slpName,
      startDate,
      endDate,
      docStatus,
    );
  }, [
    fetchData,
    currentPage,
    cardName,
    docNum,
    slpName,
    startDate,
    endDate,
    docStatus,
  ]);

  useEffect(() => {
    fetchResponsibleManagers();
  }, []);

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );

      setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  let searchInput = null;

  const handleDateRangeChange = (dates, confirm, dataIndex) => {
    debouncedSearch(dates, dataIndex, confirm);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "mijoz") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "orderStatus") {
          if (value) {
            setCurrentPage(1);
            setDocStatus(value);
          } else {
            setDocStatus("");
          }
        } else if (dataIndex === "docNum") {
          if (value) {
            setDocNum(value);
          } else {
            setDocNum("");
          }
        } else if (dataIndex === "yaratdi") {
          if (value) {
            setSlpName(value.toUpperCase());
          } else {
            setSlpName("");
          }
        } else if (dataIndex === "sana") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        confirm();
      }, 500),
    [fetchData, currentPage],
  );

  const handleButtonClick = (e, record) => {
    e.stopPropagation();
    setRemove(true);
    setSelectedRowData(record);
  };

  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "sana") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? dates : []);
                handleDateRangeChange(dates, confirm, dataIndex);
              }}
              style={{ marginBottom: 8, display: "block" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const recordDate = moment(record[dataIndex]);
            return recordDate.isBetween(startDate, endDate, "[]");
          }
          return true;
        },
        render: (text) => text,
      };
    }

    if (dataIndex === "orderStatus") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <Select
              value={selectedKeys[0] || ""}
              onChange={(value) => {
                setSelectedKeys(value ? [value] : [""]);
                debouncedSearch(value, dataIndex, confirm);
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              options={orderStatus.map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                };
              })}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (value === "") {
            return true;
          }
          return record[dataIndex]
            ? record[dataIndex].toString().toLowerCase() === value.toLowerCase()
            : "";
        },
        render: (text) => text,
      };
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const columns = columnShippedSale(t, getColumnSearchProps).map((col) => {
    if (col.dataIndex === "remove") {
      return {
        ...col,
        render: (_, record) => (
          <Button
            onClick={(e) => handleButtonClick(e, record)}
            className="bg-[#0A4D68] text-white"
            disabled={record.docStatus === "C"}
          >
            {record.docStatus === "C" ? "Yopiq" : t("remove")}
          </Button>
        ),
      };
    }

    if (col.key === "responsiblePerson") {
      return {
        ...col,
        render: (record) => {
          const responsible = record.responsiblePerson
            ? data.find((item) => {
                return item.employeeId === Number(record.responsiblePerson);
              })
            : "-";

          return (
            <span>
              {`${responsible?.firstName ? responsible?.firstName : ""} ${responsible?.lastName ? responsible?.lastName : ""} ` ||
                "-"}
            </span>
          );
        },
      };
    }

    if (col.key === "orderStatus") {
      return {
        ...col,
        render: (text, record) => {
          const status = record.orderStatus
            ? orderStatus.find((status) => {
                return status.value === record.orderStatus;
              })
            : "-";
          console.log("status=", status);
          return <span>{status.label}</span>;
        },
      };
    }

    return col;
  });

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <Header
        title={"shipments"}
        currentPage={currentPage}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={hasMoreData}
        columns={columns}
        fdata={fdata}
        loading={loading}
        handleRowClick={handleRowClick}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        formattedDate={formattedDate}
        total={total}
        scrollX={1200}
      />
      <RowDataShipmentModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => setIsModalVisible(false)}
      />
      <RemoveItemModal
        visible={isRemove}
        data={selectedRowData}
        onClose={() => {
          setRemove(false);
          fetchData(
            currentPage,
            cardName,
            docNum,
            slpName,
            startDate,
            endDate,
            docStatus,
          );
        }}
      />
    </div>
  );
};

export default Shipments;
