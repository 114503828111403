import React, { useState } from "react";
import { Modal, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ViewOutgoingPayments = ({ visible, data, onClose, refetch }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="40%"
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-5">
        <div className="w-full">
          <div className="mt-5 flex items-center justify-between gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("mijoz")}</p>
              <Input
                type="text"
                value={data.cardName || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("date")}</p>
              <Input
                type="text"
                value={moment(data?.docDate).format("DD-MM-YYYY")}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
            {/* <div className="flex flex-col gap-3">
              <p>{t("Dollar kursi")}</p>
              <Input
                type="text"
                value={data.docRate || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div> */}
          </div>
          <div className="mt-5 flex items-center justify-between gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("priceSum")}</p>
              <Input
                type="text"
                value={
                  data.docCurrency === "UZS"
                    ? new Intl.NumberFormat("fr-FR").format(data.cashSumFC)
                    : new Intl.NumberFormat("fr-FR").format(data.cashSum) || "-"
                }
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("currency")}</p>
              <Input
                type="text"
                value={data.docCurrency || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
            {/* <div className="flex flex-col gap-3">
              <p>{t("comments")}</p>
              <Input.TextArea
                rows={4}
                value={data.remarks || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div> */}
          </div>
          {/* <Table
            columns={columns}
            dataSource={data?.documentLines}
            pagination={false}
            className="border-1 mt-4 w-full border-black sm:mt-12"
            scroll={{ x: "100%" }}
          /> */}
        </div>
      </div>
    </Modal>
  );
};

export default ViewOutgoingPayments;
