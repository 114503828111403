import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Menubar from "../../../../components/WhrManager/App";
import { http } from "../../../../services/http";
import PostStockModal from "../../../../components/WhrManager/createStockModal";
import RowDataOmborModal from "../../../../components/WhrManager/row-data-ombor";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/header";
import moment from "moment";
import { debouncedSet } from "../../../../utils/debounce";
import debounce from "lodash.debounce";
const InProcess = () => {
  const { t } = useTranslation();
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docNum, setDocNum] = useState("");
  const [cardName, setCardName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [hasMore, setHasMore] = useState(moment().format("YYYY-MM-DD"));
  const [responsiblePersonData, setResponsiblePersonData] = useState([]);
  const [whsData, setWhsData] = useState([]);

  const fetchData = useCallback(
    async (DocNum, CardName, StartDate, EndDate, page) => {
      try {
        setLoading(true);
        const { data } = await http.get(
          `api/sales/inventory-transfer-requests?DocNum=${DocNum}&CardName=${CardName}&StartDate=${StartDate}&EndDate=${EndDate}&Skip=${page}`,
        );

        const formattedData = data.data;
        setHasMore(data.data.length === 20);
        setFData(formattedData);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [],
  );

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );
      setResponsiblePersonData(data.data);
      console.log("warehouse-employee", data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWarehouse = useCallback(async () => {
    try {
      const { data } = await http.get(`api/warehouses`);

      setWhsData(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchData(docNum, cardName, startDate, endDate, currentPage);
  }, [fetchData, docNum, cardName, startDate, endDate, currentPage]);

  useEffect(() => {
    fetchResponsibleManagers();
    fetchWarehouse();
  }, []);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleStartDate = (e) => {
    debouncedSet(e.target.value, setStartDate);
  };

  const handleEndDate = (e) => {
    debouncedSet(e.target.value, setEndDate);
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const handleCreateProductClick = () => {
    setIsCreateProductModalVisible(true);
  };

  const closeCreateProductModal = () => {
    setIsCreateProductModalVisible(false);
    fetchData(docNum, cardName, startDate, endDate, 0);
  };
  const closeViewModal = () => {
    setIsModalVisible(false);
    fetchData(docNum, cardName, startDate, endDate, 0);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "cardName") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "docNum") {
          if (value) {
            setDocNum(value);
          } else {
            setDocNum("");
          }
        }

        confirm();
      }, 2000),
    [fetchData, currentPage],
  );

  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
    };
  };
  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const columns = [
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docEntry"),
    },

    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      render: (text) => {
        return <span>{moment(text).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: t("deliveryConfirmedTime"),
      dataIndex: "salesOrderTime",
      key: "salesOrderTime",
      width: 250,
      render: (text) => {
        return (
          <span>{text ? moment(text).format("DD-MM-YYYY HH:mm") : "-"}</span>
        );
      },
    },
    {
      title: t("responsiblePerson"),
      dataIndex: "responsiblePerson",
      key: "responsiblePerson",
      render: (text, record) => {
        const responsible = text
          ? responsiblePersonData.find((item) => {
              return item.employeeId === Number(text);
            })
          : "-";

        return (
          <span>
            {`${responsible ? `${responsible?.firstName ?? ""} ${responsible?.lastName ?? ""}` : ""}` ||
              "-"}
          </span>
        );
      },
    },
    {
      title: t("from-whs"),
      dataIndex: "fromWarehouse",
      key: "fromWarehouse",
      render: (text, record) => {
        const whs = text
          ? whsData.find((item) => {
              return item.warehouseCode === text;
            })
          : "-";

        return (
          <span>{`${whs ? `${whs?.warehouseName ?? ""}` : ""}` || "-"}</span>
        );
      },
    },

    {
      title: t("toWhs"),
      dataIndex: "toWarehouse",
      key: "toWarehouse",
      render: (text, record) => {
        const whs = text
          ? whsData.find((item) => {
              return item.warehouseCode === text;
            })
          : "-";

        return (
          <span>{`${whs ? `${whs?.warehouseName ?? ""}` : ""}` || "-"}</span>
        );
      },
    },
    {
      title: t("OrderToDeliveryTime"),
      dataIndex: "orderToDeliveryTime",
      key: "orderToDeliveryTime",
      render: (text) => {
        return (
          <span>{text ? moment(text).format("DD-MM-YYYY HH:mm") : "-"}</span>
        );
      },
    },
    {
      title: t("deliveredTime2"),
      dataIndex: "orderDeliveredTime",
      key: "orderDeliveredTime",
      render: (text) => {
        return (
          <span>{text ? moment(text).format("DD-MM-YYYY HH:mm") : "-"}</span>
        );
      },
    },
  ];

  return (
    <div className="flex w-full">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Header
          title={"inProcess"}
          currentPage={currentPage + 1}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          hasMoreData={hasMore}
          onBtnClick={handleCreateProductClick}
          columns={columns}
          fdata={fdata}
          loading={loading}
          handleRowClick={handleRowClick}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
          startDate={startDate}
          endDate={endDate}
          formattedDate={moment().format("YYYY-MM-DD")}
          scrollX={1200}
        />
        <RowDataOmborModal
          visible={isModalVisible}
          data={selectedRowData}
          onClose={closeViewModal}
        />
        <PostStockModal
          visible={isCreateProductModalVisible}
          onClose={closeCreateProductModal}
        />
      </div>
    </div>
  );
};

export default InProcess;
