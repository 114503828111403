import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { Input, Table, Button } from "antd";
import Menubar from "../../components/SalesManager/App";
import { http } from "../../services/http";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Navbar from "../../components/navbar";

const Reports = () => {
  const { t } = useTranslation();
  const [fdata, setFData] = useState([]);
  const [wdata, setWData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);

  const searchInputRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await http.get(`api/items/ombordagiitems`);
      const formattedData = data.map((item, index) => ({
        key: index,
        itemCode: item.itemCode,
        itemName: item.itemName,
        quantityOnStock: item.quantityOnStock,
        quantityOrderedFromVendors: item.quantityOrderedFromVendors,
        quantityOrderedByCustomers: item.quantityOrderedByCustomers,
        available: item.available,
        itemWarehouseInfo: item.itemWarehouseInfo.map((wItem, wIndex) => ({
          key: wIndex + 1,
          warehouseCode: wItem.warehouseCode,
          warehouseName: wItem.warehouseName,
          inStock: wItem.inStock,
          committed: wItem.committed,
          ordered: wItem.ordered,
        })),
      }));
      setFData(formattedData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const fetchWarehouse = useCallback(async () => {
    try {
      const { data } = await http.get(`api/warehouses`);
      const mappedItems = data.map((item) => ({
        code: item.warehouseCode,
        name: item.warehouseName,
      }));
      setWData(mappedItems);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        setSearchText(value);
        setSearchedColumn(dataIndex);
        confirm();
      }, 1000),
    [],
  );
  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const handleExportExcel = async () => {
    setExcelLoading(true);
    try {
      const res = await http.post(
        "api/items/export-to-excel",
        {},
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob", // Ensure the response is treated as a blob
        },
      );

      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ombor_hisobi.xlsx"); // Define the download file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
    setExcelLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(e, confirm, dataIndex);
          }}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchData();
    fetchWarehouse();
  }, [fetchData, fetchWarehouse]);

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemName",
      key: "itemName",
      width: 380,
      fixed: "left",
      ...getColumnSearchProps("itemName"),
      render: (text) => <b>{text}</b>,
    },
    {
      title: t("quantity"),
      dataIndex: "quantityOnStock",
      key: "quantityOnStock",
      width: 150,

      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return <span>{formattedText}</span>;
      },
    },

    {
      title: t("quantityOBC"),
      dataIndex: "quantityOrderedByCustomers",
      key: "quantityOrderedByCustomers",
      width: 150,

      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return <span>{formattedText}</span>;
      },
    },
    {
      title: t("available"),
      dataIndex: "available",
      key: "available",
      width: 150,

      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return <span className="font-bold text-blue-900">{formattedText}</span>;
      },
    },
    ...wdata.map((item) => ({
      title: item.name,
      dataIndex: item.code,
      key: item.code,
      width: 150,
      render: (text, record) => {
        const warehouseInfo = record.itemWarehouseInfo.find(
          (w) => w.warehouseCode === item.code,
        );
        const inStock = warehouseInfo ? warehouseInfo.inStock : "0";
        const formattedInStock = new Intl.NumberFormat("fr-FR").format(inStock);
        return <span>{formattedInStock}</span>;
      },
    })),
  ];

  return (
    <div className="flex w-full">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <div className="flex items-center justify-between">
          <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
            {t("reports")}
          </h1>
        </div>
        <Button
          onClick={handleExportExcel}
          loading={excelLoading}
          className="ml-5 mt-10 h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
        >
          Export to Excel
        </Button>
        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

        <div className="ml-10">
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            className="mt-6 w-full sm:mt-10"
            rowKey="zakaz"
            scroll={{ x: 1500 }}
            sticky={true}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Reports;
