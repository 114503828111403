import React, { useState, useEffect } from "react";
import { Modal, Input, Table, Select, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { http } from "../../services/http";

const RowDataShipmentModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");
  const [responsibleData, setResponsibleData] = useState([]);
  const [status, setStatus] = useState("");

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "Tasdiqlandi", value: "Tasdiqlandi" },
    { label: "Mas'ulga biriktirildi", value: "Biriktirildi" },
    { label: "Buyurtma yuklandi", value: "BuyurtmaYuklandi" },
    { label: "Yuklash tasdiqlandi", value: "YuklashTasdiqlandi" },
  ];

  const handleSelectClick = () => {
    setSelectedComments(data.comments);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );

      setResponsibleData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchResponsibleManagers();
  }, []);

  useEffect(() => {
    if (data) {
      // setManager(data?.responsiblePerson ? Number(data.responsiblePerson) : "");
      // setDriverId(data.driver ? data.driver : "");
      const status = data.orderStatus
        ? orderStatus.find((status) => {
            return status.value === data.orderStatus;
          })
        : "-";

      console.log(status);
      setStatus(status.label);
    }
  }, [data]);

  if (!data) return null;

  const sum =
    data.docCur === "UZS"
      ? new Intl.NumberFormat("fr-FR").format(data.docTotalFC)
      : new Intl.NumberFormat("fr-FR").format(data.docTotal);

  const responsible = data.responsiblePerson
    ? responsibleData.find((item) => {
        return item.employeeId === Number(data.responsiblePerson);
      })
    : "-";

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
      width: 200,
    },
    {
      title: t("quantityShipped"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("quantityRemoved"),
      key: "uQuantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(
          record.quantity - record.openQty,
        );
        return `${formattedText} `;
      },
    },
    {
      title: t("openQty"),
      dataIndex: "openQty",
      key: "openQty",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} ${record.currency}`;
      },
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "openInvQty",
      key: "openInvQty",
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record) => {
        const formatSum =
          record.currency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.totalFrgn)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.currency}`;
      },
    },
    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
    },
  ];

  const tableData = data?.documentLines.map((item, index) => ({
    key: index,
    itemDescription: item.itemDescription,
    quantity: item.quantity,
    measureUnit: item.measureUnit,
    price: item.price,
    lineTotal: item.lineTotal,
    openQty: item.openQty,
    openInvQty: item.openInvQty,
    ugpName: item.ugpName,
    whsName: item.whsName,
    currency: item.currency,
    totalFrgn: item.totalFrgn,
  }));

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "70%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={data.cardName}
                className="flex w-[200px] items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t("date")}</p>
              <Input
                value={moment(data.docDate).format("DD.MM.YYYY")}
                style={{ color: "black" }}
                disabled
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
            scroll={{ x: "100%" }}
          />

          <div className="mt-10 flex items-center justify-between">
            <div className="flex flex-col gap-3">
              <p>{t("comments")}</p>
              <Select
                value={data.comments}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
                onClick={handleSelectClick}
              />
              <Modal
                title={t("comments")}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <p>{selectedComments}</p>
              </Modal>
            </div>
            <div className="flex items-center justify-end gap-5">
              <div className="flex flex-col gap-3">
                <p>{t("docTotalQuantity")}</p>
                <Input
                  type="text"
                  value={`${data.docTotalQuantity} кг`}
                  style={{ color: "black" }}
                  className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-3">
                <p>{t("totalPrice")}</p>
                <Input
                  type="text"
                  value={`${sum} ${data.docCur}`}
                  style={{ color: "black" }}
                  className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-between gap-3 sm:flex-row">
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p>{t("driver")}</p>
              <Input
                type="text"
                value={data.driverCardName}
                disabled
                style={{ color: "black" }}
                className="flex h-10 w-[180px] items-center justify-center border-[1px] border-[#D0D0D0]"
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p>{t("responsiblePerson")}</p>
              <Input
                type="text"
                value={`${responsible?.firstName ?? ""} ${responsible?.lastName ?? ""}`}
                style={{ color: "black" }}
                disabled
                className="flex h-10 w-[200px] items-center justify-center border-[1px] border-[#D0D0D0]"
              />
            </div>
          </div>

          <div className="flex flex-col gap-3 sm:mt-10">
            <div className="flex items-center gap-2">
              <span className="font-bold">{t("statusSale")} : </span>
              <Tag className="text-sm" color={"blue"}>
                {status}
              </Tag>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-bold">{t("timeRp")} : </span>

              {data?.orderToDeliveryTime && (
                <Tag color={"blue"}>
                  {moment(data?.orderToDeliveryTime).format("DD-MM-YYYY HH:mm")}
                </Tag>
              )}
            </div>

            <div className="flex items-center gap-2">
              <span className="font-bold">{t("deliveredTime2")} : </span>
              {data?.deliveredTime && (
                <Tag className="text-sm" color={"blue"}>
                  {moment(data?.deliveredTime).format("DD-MM-YYYY HH:mm")}
                </Tag>
              )}
            </div>
            <div className="flex items-center gap-2">
              <span className="font-bold">{t("confirmDeliveryTime")} : </span>

              {data?.orderDeliveredTime && (
                <Tag color={"blue"}>
                  {moment(data?.orderToDeliveryTime).format("DD-MM-YYYY HH:mm")}
                </Tag>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RowDataShipmentModal;
