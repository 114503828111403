import React, { useState, useEffect } from "react";
import { Select, DatePicker, Input, message, Spin, Button } from "antd";
import { http } from "../../../../services/http";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const fiveDaysAgo = new Date();
fiveDaysAgo.setDate(today.getDate() - 5);
const dayFiveAgo = ("0" + fiveDaysAgo.getDate()).slice(-2);
const monthFiveAgo = ("0" + (fiveDaysAgo.getMonth() + 1)).slice(-2);
const yearFiveAgo = fiveDaysAgo.getFullYear();
const minDate = `${yearFiveAgo}-${monthFiveAgo}-${dayFiveAgo}`;

function CreateProduct({ onClose, visible }) {
  const todayDate = moment().format("DD/MM/YYYY");
  let salesPersonCode = useSelector((state) => state.main.salesPersonCode);
  const employeeId = useSelector((state) => state.main.employeeId);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState({
    fdata: false,
    pdata: false,
    wdata: false,
  });

  const [data, setData] = useState({
    fdata: [],
    pdata: [],
    wdata: [],
    cardCode: "",
    warehouseCode: "",
  });

  const [items, setItems] = useState([
    {
      itemCode: "",
      quantity: "",
      unitPrice: "",
      currency: "",
      selectedPrice: null,
    },
  ]);

  const [date, setDate] = useState(formattedDate);

  const { fdata, pdata, wdata, cardCode, warehouseCode } = data;

  useEffect(() => {
    const fetchAllData = async () => {
      setLoadingData({ fdata: true, pdata: true, wdata: true });
      await Promise.all([fetchData(), fetchWarehouse(), fetchPartner()]);
      setLoadingData({ fdata: false, pdata: false, wdata: false });
    };

    fetchAllData();

    if (visible) {
      resetForm();
    }
  }, [visible]);

  const fetchData = async () => {
    try {
      const { data } = await http.get(`api/items`);
      const mappedItems = data.map((item) => ({
        code: item.itemCode,
        name: item.itemName,
        price: item.itemPrice.price,
        currency:
          item.itemPrice.currency !== null ? item.itemPrice.currency : 0,
        priceList: item.itemPrice.priceList,
      }));
      setData((prevState) => ({
        ...prevState,
        fdata: mappedItems,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const { data } = await http.get(`api/warehouses`);
      const mappedItems = data.map((item) => ({
        code: item.warehouseCode,
        name: item.warehouseName,
      }));
      setData((prevState) => ({
        ...prevState,
        wdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPartner = async () => {
    try {
      const { data } = await http.get(`api/businesspartners/mijozlar`);
      const mappedItems = data.map((item) => ({
        code: item.cardCode,
        name: item.cardName,
      }));
      setData((prevState) => ({
        ...prevState,
        pdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChange = (index, value) => {
    const selectedItem = fdata.find((item) => item.name === value);
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      selectedPrice: selectedItem.price,
      itemCode: selectedItem.code,
      unitPrice: selectedItem.price,
      currency: selectedItem.currency,
    };
    setItems(newItems);
  };

  const handleMijozChange = (value) => {
    const selectedItem = pdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      cardCode: selectedItem.code,
    }));
  };

  // const handleOmborChange = (value) => {
  //   const selectedItem = wdata.find((item) => item.name === value);
  //   setData((prevState) => ({
  //     ...prevState,
  //     warehouseCode: selectedItem.code,
  //   }));
  // };

  const handleQuantityChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], quantity: value };
    setItems(newItems);
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        itemCode: "",
        quantity: "",
        unitPrice: "",
        currency: "",
        selectedPrice: null,
      },
    ]);
  };

  const postData = async () => {
    const allItemsValid = items.every(
      (item) => item.itemCode && item.quantity > 0,
    );
    if (cardCode && employeeId && allItemsValid) {
      setLoading(true);
      try {
        const documentLines = items.map((item, index) => ({
          itemCode: item.itemCode,
          quantity: item.quantity,
          lineNum: index,
          // warehouseCode: warehouseCode,
          currency: item.currency !== null ? item.currency : "USD",
          unitPrice: item.unitPrice,
        }));

        await http.post("api/yangisotuvyaratish", {
          cardCode,
          salesPersonCode,
          documentsOwner: employeeId,
          docDueDate: date,
          docDate: date,
          documentLines,
        });
        message.success(t("sucCreated"));

        setTimeout(() => {
          resetForm();
          onClose();
        }, 800);
      } catch (error) {
        message.error(t("errCreation") + error.message);
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("fillForm"));
    }
  };

  const resetForm = () => {
    setItems([
      {
        itemCode: "",
        quantity: "",
        unitPrice: "",
        currency: "",
        selectedPrice: null,
      },
    ]);
    setData((prevState) => ({
      ...prevState,
      cardCode: "",
      warehouseCode: "",
    }));
  };

  return (
    <div className="w-full px-4 sm:px-14">
      <div className="w-full">
        <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row sm:gap-0">
          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("mijoz")}</p>
            <Spin spinning={loadingData.pdata}>
              <Select
                value={
                  cardCode
                    ? pdata.find((item) => item.code === cardCode)?.name
                    : undefined
                }
                placeholder={t("choose")}
                showSearch
                optionFilterProp="children"
                onChange={handleMijozChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="flex h-12 w-full items-center justify-center sm:w-[300px]"
              >
                {pdata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.code} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("date")}</p>
            <Input
              type="date"
              value={date}
              className="w-[150px]"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              max={formattedDate}
              min={minDate}
            />
          </div>
        </div>

        <div className="mt-10 flex flex-col items-start justify-between gap-5 sm:flex-row sm:items-end">
          <div className="flex w-full flex-col sm:w-auto">
            {items.map((item, index) => (
              <div
                key={index}
                className="mt-1 flex flex-col items-start gap-5 sm:flex-row sm:items-center"
              >
                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("item")}</p> : ""}
                  <Spin spinning={loadingData.fdata}>
                    <Select
                      value={
                        item.itemCode
                          ? fdata.find((fItem) => fItem.code === item.itemCode)
                              ?.name
                          : undefined
                      }
                      placeholder={t("choose")}
                      onChange={(value) => handleSelectChange(index, value)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      className="flex h-12 w-full items-center justify-center sm:w-[430px]"
                    >
                      {fdata
                        .filter((fItem) => fItem.name !== null)
                        .map((fItem) => (
                          <Select.Option key={fItem.code} value={fItem.name}>
                            {fItem.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Spin>
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-[130px]">
                  {index === 0 ? <p>{t("price")}</p> : ""}
                  <Input
                    value={item.selectedPrice}
                    style={{ color: "black" }}
                    className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[130px]"
                    disabled
                  />
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("quantity")}</p> : ""}
                  <Input
                    value={item.quantity}
                    onChange={(e) =>
                      handleQuantityChange(index, e.target.value)
                    }
                    type="text"
                    placeholder={t("quantity")}
                    className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[80px]"
                  />
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("priceSum")}</p> : ""}
                  <Input
                    value={Number(item.quantity) * Number(item.selectedPrice)}
                    style={{ color: "black" }}
                    disabled
                    className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[80px]"
                  />
                </div>
                {items.length > 1 && (
                  <button
                    onClick={() => removeItem(index)}
                    className={`mt-2 h-7 w-[30px] border-2 border-black bg-[#0A4D68] text-red-500 ${index === 0 ? "sm:mt-7" : "sm:mt-1"}`}
                  >
                    -
                  </button>
                )}
              </div>
            ))}
          </div>

          <div>
            <button
              onClick={addItem}
              className="mb-3 h-7 w-[30px] border-2 border-black bg-[#0A4D68] text-white"
            >
              +
            </button>
          </div>
        </div>

        {/* <div className="mt-10 flex flex-col items-start gap-5 sm:flex-row sm:items-center">
          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p>{t("whs")}</p>
            <Spin spinning={loadingData.wdata}>
              <Select
                value={
                  warehouseCode
                    ? wdata.find((item) => item.code === warehouseCode)?.name
                    : undefined
                }
                placeholder={t("choose")}
                showSearch
                optionFilterProp="children"
                onChange={handleOmborChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
              >
                {wdata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.code} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>
        </div> */}

        <div className="mt-24 flex justify-center">
          <Button
            onClick={postData}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[350px]"
            disabled={loading}
            loading={loading}
          >
            {loading ? t("saving") : t("saveAndCreate")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateProduct;
